import styled from "styled-components"

export const Wrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
`

export const UnstyledList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
`

export const H1 = styled.h1`
  text-align: center;
  font-family: franklin-gothic-urw, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 2rem;
  color: #615c53;
  line-height: 2.7rem;
  margin-top: 2.6rem;
  margin-bottom: 1.3rem;
  text-transform: lowercase;

  @media (min-width: 640px) {
    font-size: 2.375rem;
    margin-top: 6.5rem;
    margin-bottom: 3.9rem;
  }
`

export const A = styled.a`
  color: #9a6e67;
  border-bottom: 1px solid rgba(154, 110, 103, 0.3);
  padding-bottom: 1px;
  text-decoration: none;
  transition: all 0.2s linear;

  &:hover {
    border-color: #9a6e67;
  }
`
