/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Fragment } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import PageTransition from "gatsby-plugin-page-transitions"

import Header from "./header"
import "./layout.css"
import Logo from "../images/logo@2x.png"

const Grid = styled.div`
  display: none;
  position: absolute;
  left: 0px;
  right: 0px;
  pointer-events: none;
  user-select: none;
  top: 0px;
  height: 1600px;
  background: linear-gradient(rgba(0, 119, 179, 0.2) 1px, transparent 1px) left
    top / 21px 21px;
`

const Main = styled.main``

const Footer = styled.footer`
  padding: 3rem 0;
  margin-top: 2.6rem;
  text-align: center;
  font-family: franklin-gothic-urw, sans-serif;
  font-weight: 300;
  background-color: #fff5e3;
  color: #615c53;
  line-height: 1.3rem;
  font-size: 1rem;
  display: flex;
  flex-direction: column;

  @media (min-width: 640px) {
    margin-top: 6.5rem;
    padding: 4.6rem 0;
  }

  span a {
    color: #9a6e67;
    border-bottom: 1px solid rgba(154, 110, 103, 0.3);
    padding-bottom: 1px;
    text-decoration: none;
    transition: all 0.2s linear;

    &:hover {
      border-color: #9a6e67;
    }
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const siteTitle = data.site.siteMetadata.title

  return (
    <Fragment>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/zgv8zgt.css" />
      </Helmet>
      <Grid />
      <Header siteTitle={siteTitle} />
      <PageTransition>
        <Main>{children}</Main>
      </PageTransition>
      <Footer>
        <Link to="/">
          <img src={Logo} width={75} alt={siteTitle} />
        </Link>
        <span>
          © {new Date().getFullYear()}, built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </span>
      </Footer>
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
