import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import { UnstyledList, Wrapper } from "./commons"
import Logo from "../images/logo@2x.png"

const StyledHeader = styled.header`
  background-color: #fff5e3;
  padding: 2.7rem 0 2.6rem;

  @media (min-width: 640px) {
    flex-direction: row;
    padding: 4.3rem 0;
  }
`

const StyledWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 0 1rem;

  @media (min-width: 640px) {
    flex-direction: row;
  }

  img {
    margin-bottom: 0;
    display: block;
  }
`

const Nav = styled.nav`
  margin-top: 1.8rem;

  @media (min-width: 640px) {
    margin-top: 0;
  }

  ul {
    font-family: franklin-gothic-urw, sans-serif;
    font-weight: 400;
    display: flex;
    font-size: 1.25rem;
  }

  li {
    margin-right: 1rem;

    @media (min-width: 640px) {
      margin-right: 2rem;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    color: #615c53;
    text-decoration: none;
    transition: color 0.2s linear;

    &.active {
      color: #9a6e67;
    }

    &:hover {
      color: #9a6e67;
    }
  }
`

const Header = ({ siteTitle }) => (
  <StyledHeader>
    <StyledWrapper>
      <Link to="/">
        <img src={Logo} width={75} alt={siteTitle} />
      </Link>
      <Nav>
        <UnstyledList>
          <li>
            <Link activeClassName="active" to="/">
              looks
            </Link>
          </li>
          <li>
            <Link activeClassName="active" to="/about">
              about
            </Link>
          </li>
          <li>
            <a href="https://www.instagram.com/langtid.co">instagram</a>
          </li>
        </UnstyledList>
      </Nav>
    </StyledWrapper>
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
